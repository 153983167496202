<template>
  <div class="Search" :style="'min-height:' + divHeight + 'px; overflow-y: auto;'">
    <div class="page-nav">
      <header>
        <template>
          <i>
            <van-icon name="arrow-left" @click="goback" />
          </i>
          <span>搜索成員數據</span>
          <i></i>
        </template>
      </header>
    </div>

    <div class="page-content bg-light-blue">
      <van-search v-model="keyword" placeholder="請輸入搜索關鍵詞" @search="searchAccount" />

      <div class="card p-4 pt-6 mt-4" v-if="showProfile">
        <!-- <div class="account-profile">
          <img class="thumb" :src="account_thumb_url" />
          <div :style="'padding: .5em 1em;'">
            <div>
              <h1>{{ account_info.nick_name }}</h1>
            </div>
            <div :style="'margin-top: .4em;'">
              <p class="text-grey-blue">ID: {{ account_info.alias_id }}</p>
            </div>
          </div>
        </div> -->
        <div class="panel-header">賬戶概況 (ID: {{ account_info.alias_id }})</div>
        <div class="card account-stock-overview mb-1 p-2">
          <div class="row">
            <div class="column column-6 p-1">
              <p class="text-center mini-text">持股量</p>
              <h3>{{ numberFormat(account_info.score) }} <small>股</small></h3>
            </div>
            <div class="column column-6 p-1">
              <p class="text-center mini-text">持股市價</p>
              <h3>{{ numberFormat(account_total_stock_value) }} <small>元</small></h3>
            </div>
          </div>
          <div class="row">
            <div class="column column-6 p-1">
              <p class="text-center mini-text">今日漲幅</p>
              <h2>
                <template v-if="account_today_stock_amplitude > 0">
                  <img src="../../assets/imgs/caret-green.png" class="mx-1" />
                  <span class="stock-green">{{ numberFormat(account_today_stock_amplitude) }} %</span>
                </template>
                <template v-if="account_today_stock_amplitude < 0">
                  <img src="../../assets/imgs/caret-red.png" class="mx-1" />
                  <span class="stock-red">{{ numberFormat(account_today_stock_amplitude) }} %</span>
                </template>
                <template v-if="!account_today_stock_amplitude">0 %</template>
              </h2>
            </div>
            <div class="column column-6 p-1">
              <p class="text-center mini-text">累積漲幅</p>
              <h2>
                <template v-if="account_total_stock_amplitude > 0">
                  <img src="../../assets/imgs/caret-green.png" class="mx-1" />
                  <span class="stock-green">{{ numberFormat(account_total_stock_amplitude) }} %</span>
                </template>
                <template v-if="account_total_stock_amplitude < 0">
                  <img src="../../assets/imgs/caret-red.png" class="mx-1" />
                  <span class="stock-red">{{ numberFormat(account_total_stock_amplitude) }} %</span>
                </template>
                <template v-if="!account_total_stock_amplitude">0 %</template>
              </h2>
            </div>
          </div>
        </div>

        <div class="card bg-light-blue mt-4 actions">
          <div class="row my-1">
            <div class="column-6 text-grey-blue py-1">
              <p>密碼</p>
            </div>
            <div class="column-6 text-grey-blue py-1 text-right">
              {{ account_info.login_password }} <van-icon v-if="canEditPassword" @click="showEditPasswordSheet = true"
                name="edit" />
            </div>
          </div>
          <hr />
          <div class="row my-1">
            <div class="column-6 text-grey-blue py-1">
              <p>管理權限</p>
            </div>
            <div class="column-6 text-grey-blue py-1 text-right">
              <template v-if="isManager">是&nbsp;&nbsp;</template>
              <template v-if="!isManager">否&nbsp;&nbsp;</template>
              <van-switch v-if="canEditPermission" v-model="isManager" active-color="#2563EB" size="12px"
                @input="updateAccountPermission" />
            </div>
          </div>
          <hr />
          <div class="row my-1">
            <div class="column-6 text-grey-blue py-1">
              <p>持股數</p>
            </div>
            <div class="column-6 text-grey-blue py-1 text-right">{{ account_info.score }} <van-icon v-if="canEditScore"
                @click="showEditScoreSheet = true" name="edit" /></div>
          </div>
        </div>
      </div>
    </div>

    <van-action-sheet v-model="showEditPasswordSheet" title="修改密碼">
      <div class="shuru">
        <div class="inp mb-4">
          <van-field v-model="form.login_password" type="password" placeholder="請輸入密碼" />
        </div>
        <div class="inp">
          <van-field v-model="form.confirm_login_password" type="password" placeholder="請再輸入一次密碼" />
        </div>
      </div>
      <div class="row mt-1 mb-6 actions">
        <div class="column-6"><button class="button btn-cancel" @click="showEditPasswordSheet = false">取消</button></div>
        <div class="column-6"><button class="button btn-red" @click="updateAccountPassword">確認</button></div>
      </div>
    </van-action-sheet>

    <van-action-sheet v-model="showEditScoreSheet" title="修改持股數">
      <div class="shuru">
        <div class="inp mb-4 text-grey-blue">
          目前持股數 {{ account_info.score }}
        </div>
        <div class="inp">
          <van-field v-model="form.score" type="number" placeholder="持股數" />
        </div>
      </div>
      <div class="row mt-1 mb-6 actions">
        <div class="column-6"><button class="button btn-cancel" @click="showEditScoreSheet = false">取消</button></div>
        <div class="column-6"><button class="button btn-red" @click="updateAccountScore">確認</button></div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { createIcon } from "@/utils/blockies";
export default {
  name: "Search",
  data() {
    return {
      divHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      keyword: '',
      showProfile: false,
      showEditPasswordSheet: false,
      showEditScoreSheet: false,
      canEditPassword: false,
      canEditPermission: false,
      canEditScore: false,
      account_today_stock_amplitude: 0,
      account_total_stock_value: 0,
      account_total_stock_amplitude: 0,
      account_info_default: {

      },
      account_info: {
        "alias_id": 0,
        "nick_name": "",
        "score": 0,
        "today_score": 0,
        "total_score": 0,
        "login_times": 0,
        "last_login_ip": "",
        "last_login_at": "",
        "last_password": null,
        "permission_level": 0,
        "can_edit_password": false,
        "can_edit_permission": false,
        "can_edit_scroe": false
      },
      account_thumb_url: '',
      isManager: false,
      form: {
        login_password: '',
        confirm_login_password: '',
        score: 0,
      }
    };
  },
  components: {
  },
  mounted() {

  },
  created() {
    this.account_info_default = this.account_info;
  },
  methods: {
    createIcon,
    goback() {
      this.$router.go(-1);

      this.keyword = '';

      this.showProfile = false;

      this.canEditPassword = false;
      this.canEditPermission = false;
      this.canEditScore = false;

      this.account_info = this.account_info_default;
    },
    numberFormat(v, d) {

      if (typeof v === 'undefined' || isNaN(v)) {
        v = 0;
      }

      if (typeof d === 'undefined' || isNaN(d)) {
        d = 1;
      }

      let n = Math.floor(v / d * 100) / 100;

      return new Intl.NumberFormat().format(n);
    },
    searchAccount(val) {
      if (val.length != 6) {
        this.showProfile = false;
        this.$toast("查無會員！");
        return;
      }

      this.$api
        .getAccountDetail({
          alias_id: val,
        })
        .then(res => {
          if (res.status == 200) {
            if (res.data.alias_id) {
              this.isManager = res.data.permission_level > 0;
              this.canEditPassword = res.data.can_edit_password;
              this.canEditPermission = res.data.can_edit_permission;
              this.canEditScore = res.data.can_edit_scroe;
              this.account_info = res.data;
              this.account_thumb_url = createIcon(this.account_info.alias_id.toString());
              this.showProfile = res.data.alias_id ? true : false;

              this.$api
                .getAccountStockStatus({
                  alias_id: val,
                })
                .then(res => {
                  if (res.status == 200) {
                    if (res.data.account_alias_id) {
                      this.account_total_stock_value = res.data.account_total_stock_value;
                      this.account_total_stock_amplitude = res.data.account_total_stock_amplitude;
                      this.account_today_stock_amplitude = res.data.account_today_stock_amplitude;
                      return;
                    }
                  }
                });
              return;
            }
          }

          this.showProfile = false;
          this.$toast("查無會員！");
        });
    },
    updateAccountPassword() {

      if (this.form.login_password == '') {
        this.$toast("密碼不能為空！");
        return false;
      }

      if (this.form.login_password != this.form.confirm_login_password) {
        this.$toast("密碼輸入不一樣！");
        return false;
      }

      this.$api
        .updateAccountPassword({
          alias_id: this.account_info.alias_id,
          login_password: window.btoa(this.form.login_password),
          confirm_login_password: window.btoa(this.form.confirm_login_password),
        })
        .then(res => {

          if (res.status == 200) {
            this.showEditPasswordSheet = false;
            this.form.login_password = '';
            this.form.confirm_login_password = '';

            this.searchAccount(this.account_info.alias_id.toString());

            this.$toast("修改成功！");
          } else {
            //
          }
        });
    },
    updateAccountPermission(checked) {

      this.isManager = checked;

      this.$api
        .updateAccountPermission({
          alias_id: this.account_info.alias_id,
          permission_level: checked ? 1 : 0,
        })
        .then(res => {

          if (res.status == 200) {
            this.showEditScoreSheet = false;
            this.form.score = '';

            this.searchAccount(this.account_info.alias_id.toString());

            this.$toast("修改成功！");
          } else {
            //
          }
        });
    },
    updateAccountScore() {

      if (this.form.score == '') {
        this.$toast("持股數不能為空！");
        return false;
      }

      if (this.form.score < 0) {
        this.$toast("持股數不得小於0！");
        return false;
      }

      this.$api
        .updateAccountScore({
          alias_id: this.account_info.alias_id,
          score: this.form.score,
        })
        .then(res => {

          if (res.status == 200) {
            this.showEditScoreSheet = false;
            this.form.score = '';

            this.searchAccount(this.account_info.alias_id.toString());

            this.$toast("修改成功！");
          } else {
            //
          }
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.Search {
  width: 100%;

  .page-nav {
    width: 100%;
    height: 100px;

    header {
      width: 100%;
      height: 100px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      // background-color: #17181A;
      // background-size: 100% 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;

      .fan,
      i {
        width: 20px;
      }

      .members,
      .bars,
      i {
        width: 50px;
      }
    }
  }

  .van-search {
    padding: 0;
  }

  .van-search__content {
    background-color: #e8ebf1;
  }

  .actions {
    p {
      font-size: .8em;
      font-weight: 400;
    }

    hr {
      margin: .75em 0;
    }
  }

  i.van-icon.van-icon-edit {
    color: #ff1414 !important;
    font-weight: 900;
    position: relative;
    top: 4px;
  }

  .shuru {
    width: 85%;
    margin-left: 7.5%;

    .inp {
      background-color: #FAFAFA;
      padding: .75em 1em;
      border-radius: .7em;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .van-cell {
      padding: 0;
      background: none !important;
    }
  }

  .actions {
    button {
      width: 100%;
      padding: 35px 0;
      background-color: #12d18e;
      border-radius: .3em;
      border: none;
      color: #ffffff;
    }
  }
}
</style>